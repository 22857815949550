'use client';
import styles from './500.module.scss';
import { Button, Result } from 'antd';
import Image from 'next/image';
import errorStateLight from 'assets/images/500.svg';
import { GlobalContext } from 'context';
import { useRouter } from 'next/navigation';
import { useContext, useEffect } from 'react';

const Error500Comp: React.FC<{ reset: () => void; error?: any }> = (props) => {
    const lang = useContext(GlobalContext).langText.error;
    const router = useRouter();
    useEffect(() => {
        console.log(props.error);
    });
    return (
        <div className={`${styles['container']} m-auto`}>
            <Result
                icon={<Image className={styles.image} src={errorStateLight} alt="error image" />}
                title={<p>{lang.title}</p>}
                subTitle={<p>{lang.subTitle}</p>}
                extra={
                    <>
                        <Button className="ph-3-i" key={'sada'} type="primary" onClick={() => router.back()}>
                            {lang.back}
                        </Button>
                        <Button className="ph-3-i" key={'0asdf0'} onClick={() => props.reset()}>
                            {lang.tryAgain}
                        </Button>
                    </>
                }
            />
        </div>
    );
};
export default Error500Comp;
